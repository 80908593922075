<template>
    <div>

        <div class="card">
            <h3 class="card_title">
                <indicator class="marginAuto" :condition="!verifiedSteps.setup" required="1" :ttle="verifiedSteps.setup"></indicator>
                Rüstkosten
            </h3>
            <div id="infoGrid" class="padding">
                <p>Rüstzeit pro Werkzeug</p>
                <input type="number" :value="setup.time_perTool" @input="changeValue('time_perTool', $event.target.value)">
                <p>min</p>

                <p>Rüststundensatz</p>
                <input class="textspacing" type="number" :value="setup.cost_hourly" @input="changeValue('cost_hourly', $event.target.value)">
                <p>€/h</p>

                <p>Programmierzeit:</p>
                <input class="textspacing" type="number" :value="setup.programmingtime" @input="changeValue('programmingtime', $event.target.value)">
                <p>min</p>

                <p>Sonstige Rüstzeit:</p>
                <input type="number" :value="setup.otherTime" @input="changeValue('otherTime', $event.target.value)">
                <p>min</p>
            </div>            
        </div>
        
    </div>
</template>

<script>

export default {
    name: "Setup",
    data() {
        return {
        }
    },
    computed: {
        setup() {
            let values = this.$store.state.calculation.setup
            let machine = this.$store.getters.machine
            //  User parameters from machine
            console.log(values, machine);
            if (values.time_perTool == undefined && machine.setup_time_perTool) this.changeValue("time_perTool", machine.setup_time_perTool)
            if (values.cost_hourly == undefined && machine.setup_cost_hourly) this.changeValue("cost_hourly", machine.setup_cost_hourly)
            console.log(values);
            return values
        },
        verifiedSteps() {
            return this.$store.getters.verifiedSteps
        }
    },
    methods: {
        dict(c) {
            return this.$store.state.dict[c] || c
        },
        changeValue(key, value) {
            this.$store.commit("setSetupValue", [key, value])
            this.$store.dispatch("refresh")
        }
    },
    watch: {
    }
};
</script>

<style scoped>
    #infoGrid{
        display: grid;
        grid-template-columns: auto 4em 4em;
        grid-column-gap: .5em;
        grid-row-gap: 4px;
    }
    #infoGrid>*:nth-child(3n+2) {text-align: right;}
</style>
